import ClickSvg from "./images/click.svg?react"
import PostviewSvg from "./images/postview.svg?react"
import SeoSvg from "./images/seo.svg?react"
import ViewSvg from "./images/view.svg?react"
import {
    Box,
    CircularProgress,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from "@mui/material"
import { ConversionListContextSelectors } from "domain/ConversionList/context/ConversionListContextSelectors"
import { tpTsColumnConfig } from "domain/ConversionList/domain/columnConfigurations"
import {
    CONVERSION_LIST_TP_TS_VALUE_COLUMN_FIELD,
    CONVERSION_LIST_TP_TYPE,
} from "domain/ConversionList/domain/dimensionIdentifiers"
import { ConversionListColumnDetails } from "domain/ConversionList/domain/domain"
import { ColumnField } from "domain/dimension/service/DimensionService"
import { ColumnResponseDTO } from "generated/models"
import { DRAWER_WIDTH_EXPANDED } from "layout/MainLayout/constants"
import React from "react"
import { agnosticRenderer } from "shared/component/renderers/renderers"
import { FONT_WEIGHT_SEMI_BOLD } from "styles/theme/constants"

type CustomerJourneyPanelProps = Readonly<{
    transactionUid: string
    onContextMenu?: (event: React.MouseEvent) => void
}>

export const CustomerJourneyPanel = ({ transactionUid, onContextMenu }: CustomerJourneyPanelProps) => {
    const { columnDetails: contextColumnDetails } = ConversionListContextSelectors.useColumns()
    const selectedColumns = ConversionListContextSelectors.useSelectedColumns().filter((column) => {
        const columnDetails = contextColumnDetails[column]
        return columnDetails && columnDetails.columnCategory === "winningTouchpoint"
    })

    const contextColumnDetailsWithTpTs: Record<string, ConversionListColumnDetails> = {
        [CONVERSION_LIST_TP_TS_VALUE_COLUMN_FIELD]: {
            fieldName: CONVERSION_LIST_TP_TS_VALUE_COLUMN_FIELD,
            columnCategory: "winningTouchpoint",
            supportsSearch: false,
            columnConfigDTO: tpTsColumnConfig,
        },
        ...contextColumnDetails,
    }
    const selectedColumnsWithTpTs = [CONVERSION_LIST_TP_TS_VALUE_COLUMN_FIELD, ...selectedColumns]

    const queryConfig = ConversionListContextSelectors.useCurrentQueryConfig()
    const customerJourney = ConversionListContextSelectors.useLoadCustomerJourneyQuery({
        queryConfig,
        transactionUid,
    })

    if (customerJourney.isPending) {
        return (
            <CircularProgress
                className="cjLoadingIndicator"
                size={24}
                sx={{
                    position: "relative",
                    left: {
                        // The navbar widths depends on the screen size, so we have to copy its breakpoints
                        lg: `calc(50vw + 24px + 30px - ${DRAWER_WIDTH_EXPANDED})`,
                    },
                    m: 1,
                }}
            />
        )
    }

    if (customerJourney.isError) {
        return <Box sx={{ m: 1 }}>There was an error while fetching your data. Please try again later.</Box>
    }

    // const customerJourneyColumns = makeCustomerJourneyColumns(customerJourneyPageConfig.data.elementConfig)

    return (
        <div className="customer-journey-panel">
            <TableContainer
                component={Paper}
                className="customer-journey-table-container"
                sx={{
                    width: "auto",
                    display: "inline-block",
                    ml: "48px", // Align with first data column
                    mr: 2, // Right margin
                    mt: 0.5, // Reduced top margin
                    mb: 1, // Bottom margin
                    borderRadius: "4px",
                    boxShadow: "none", // Remove default shadow
                    border: (theme) => `1px solid ${theme.palette.divider}`, // Add subtle border
                    overflow: "hidden",
                }}
            >
                <Table
                    onContextMenu={onContextMenu}
                    className="customer-journey-table"
                    size="small"
                    sx={{
                        tableLayout: "auto", // Enable auto-sizing columns
                        width: "auto", // Make table only as wide as its content
                        "& .MuiTableCell-root": {
                            padding: "4px 8px", // Reduced padding for more compact cells
                            fontSize: "12px", // Smaller font size
                            whiteSpace: "nowrap", // Prevent text wrapping
                        },
                        "& .MuiTableRow-body:hover": {
                            backgroundColor: (theme) =>
                                theme.palette.mode === "light" ? theme.palette.grey[50] : theme.palette.grey[900],
                        },
                    }}
                >
                    <TableHead className="customer-journey-table-head">
                        <TableRow>
                            <TableCell
                                key={"tp_icon"}
                                sx={{
                                    width: "16px", // Fixed width for icon column
                                    backgroundColor: (theme) =>
                                        theme.palette.mode === "light"
                                            ? theme.palette.grey[100]
                                            : theme.palette.grey[800],
                                }}
                            ></TableCell>
                            {selectedColumnsWithTpTs.map((fieldName) => {
                                // Set minimum width for specific column types
                                let minWidth = "auto"
                                const header =
                                    contextColumnDetailsWithTpTs[fieldName]!.columnConfigDTO.gridColumnProperties
                                        .columnHeader

                                // Adjust min-width based on column type
                                if (header.includes("Time") || header.includes("Date")) {
                                    minWidth = "120px"
                                } else if (header.includes("Action") || header.includes("Type")) {
                                    minWidth = "80px"
                                } else if (header.includes("Position")) {
                                    minWidth = "60px"
                                } else if (header.includes("Channel")) {
                                    minWidth = "100px"
                                }

                                return (
                                    <TableCell
                                        key={fieldName}
                                        sx={{
                                            fontWeight: FONT_WEIGHT_SEMI_BOLD, // Make headers bold
                                            backgroundColor: (theme) =>
                                                theme.palette.mode === "light"
                                                    ? theme.palette.grey[100]
                                                    : theme.palette.grey[800],
                                            minWidth,
                                        }}
                                    >
                                        {
                                            contextColumnDetailsWithTpTs[fieldName]!.columnConfigDTO
                                                .gridColumnProperties.columnHeader
                                        }
                                    </TableCell>
                                )
                            })}
                        </TableRow>
                    </TableHead>
                    <TableBody className="customer-journey-table-body">
                        {customerJourney.data.dataSet.rows.map((row, idx) => {
                            return (
                                <TableRow key={idx} hover>
                                    <TableCell key={`${idx}_tp_icon`}>
                                        <TpIcon actionTypeResponse={row[CONVERSION_LIST_TP_TYPE]} />
                                    </TableCell>
                                    {selectedColumnsWithTpTs.map((fieldName) => {
                                        const identifier = ColumnField.recognize(fieldName).identifier

                                        const dataColumn = contextColumnDetailsWithTpTs[fieldName]!.columnConfigDTO
                                        const renderer = dataColumn.gridColumnProperties.renderer
                                            ? agnosticRenderer(dataColumn)
                                            : (value: ColumnResponseDTO) => value?.value
                                        const columnValue = row[identifier]

                                        return (
                                            <TableCell key={`${idx}_${fieldName}`}>
                                                {columnValue ? renderer(columnValue) : ""}
                                            </TableCell>
                                        )
                                    })}
                                </TableRow>
                            )
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    )
}

const TpIcon = ({ actionTypeResponse }: { actionTypeResponse?: ColumnResponseDTO }) => {
    const type = actionTypeResponse?.value?.toString()

    const styles = { marginTop: "8px", width: "16px", height: "16px" }

    switch (type) {
        case "Click":
            return <ClickSvg style={styles} />
        case "Postview":
            return <PostviewSvg style={styles} />
        case "View":
            return <ViewSvg style={styles} />
        case "SEO":
            return <SeoSvg style={styles} />
        default:
            return null
    }
}
